* {
  margin: 0;
  padding: 0;
  font-family: 'Lora', serif;
  font-size: 16px;  
}

body {
  background-color: #FCFCFC;
}

header {
  display: grid;
  grid-template-columns: 1fr;
  top:0;
  position: fixed;
  background: #FCFCFC;
  width: 100%;
}

.logo {
  padding-top: 0.5em;
  padding-bottom: 0.1em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 18px;
  text-align: center;
  color: #344A37;
}

.header-title{
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 12px;
  text-align: center;
  color: #344A37;
}


.content {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 7.5em;
}

/* Evetn Styles */
.event{
  margin-top: 1em;
}

.event img{
  width: 100%;
}
.event h2 {
  font-size: 18px;
}
.event a{
  text-decoration: none;
  color: black;
}
.event .eventDate {
  font-style: italic;
}
.event p {
  font-size: 14px;
}

/* Footer */
footer {
  grid-area: footer;
  color: #F2F2F2;
  background-color: rgb(17, 4, 39);
  height: 3.5em;
  margin-top: 1.5em;
  text-align: center;
  padding: 1em;
}

footer > h3 {
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.2em;
}

footer > h4 {
  padding-top: 0.5em;
  font-weight: normal;
}

/* Date Search Form*/
.search-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 1em;
  grid-auto-rows: 2.5em;
  margin-left: 3em;
  margin-right: 3em;
  margin-top: 7em;
}

.search-form .date-input{
  min-width: 95%;
  width: 100%;
  font-size: 14px;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
}

.search-form .btn {
  border: none;
  background: rgb(250, 83, 6);
  color: #FFFFFF;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 100%;
  min-width: 100%;
  min-height: 2.5em;
  border-radius: 5px;
  -webkit-box-sizing: border-box;
  -webkit-appearance: none;
}

.search-form .btn:hover{
  background:rgb(236, 71, 6);
}

/* Show More Button */
.show-more-container{
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 2.5em;
  margin-top: 2em;
  margin-left: 2em;
  margin-right: 2em;
}
.show-more-btn {
  border: none;
  background: rgb(240, 11, 106);
  color: #FFFFFF;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 100%;
  min-width: 100%;
  min-height: 2.5em;
  border-radius: 5px;
}

.show-more-btn:hover{
  background:rgb(224, 11, 100);
}

/* Quick Filter */
.quick-filter-container {
  background: rgb(3, 146, 165);
  display: grid;
  grid-template-columns: repeat(4, calc(50% - 40px));
  grid-template-rows: minmax(40px, 0.5fr);
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  margin-top: 0.5em;
}  

.filter-link {
  text-decoration: none;
  border: none;
  background: rgb(3, 146, 165);
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  padding: 1em;
}

.active {
  background:  rgb(13, 167, 214);
}

/* LARGE SCREENS */
@media(min-width: 600px){
  header {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 1em;
  }

  .logo {
    font-size: 20px;
    text-align: left;
    padding: 0;
    color: #344A37;
    grid-column: 1 / 3;
    width: 100%;
  }

  .header-title{
    width: 100%;
    font-size: 18px;
    color: #344A37;
    width: 100%;
    padding-top: 0.3em;
  }

  .content {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5em;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  
  .event{
    margin-top: 0.5em;
  }  
  
  .search-form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 2em;
    grid-column-gap: 1em;
    margin-left: 1.5em;
    margin-right: 1.5em;
  }

  .show-more-container{
    grid-template-columns: repeat(3, 1fr);
  }
  .show-more-btn {
    grid-column: 2;
  }

  .quick-filter-container {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: none;
    grid-column: 1 / 4;
    width: 100%;
  }
}

@media(min-width: 900px){
  .content {
    grid-template-columns: repeat(3, 1fr);
  }
  .show-more-btn {
    font-size: 18px;
  }

  .logo{
    padding-left: 2em; 
  }

  .search-form {
    margin-bottom: 2em;
  }
}

@media(min-width: 1200px){
  .content {
    grid-template-columns: repeat(4, 1fr);
  }
}
